import { SET_MAIN_INFO, SET_MAIN_INFO_REQUEST, SET_MAIN_INFO_FAILURE } from '../types/mainInfo';
import { mainInfoAPI } from '../../api/api';

const setMainInfo = data => ({ type: SET_MAIN_INFO, data });

const setMainInfoRequest = () => {
  return {
    type: SET_MAIN_INFO_REQUEST,
  };
};

const setMainInfoFailure = error => {
  return {
    type: SET_MAIN_INFO_FAILURE,
    error,
  };
};

export const addMainInfo = newData => async dispatch => {
  dispatch(setMainInfoRequest());

  try {
    await mainInfoAPI.addMainInfo(newData);
    const data = await mainInfoAPI.getMainInfo();
    dispatch(setMainInfo(data));
  } catch (error) {
    dispatch(setMainInfoFailure(error));
  }
};

export const getMainInfo = () => {
  return async dispatch => {
    dispatch(setMainInfoRequest());

    try {
      const data = await mainInfoAPI.getMainInfo();
      dispatch(setMainInfo(data));
    } catch (error) {
      dispatch(setMainInfoFailure(error));
    }
  };
};

export const updateBanner = (_id, title, titleLong, photo) => {
  return {};
};

// editMainInfo

export const updateMainInfo = newData => async dispatch => {
  dispatch(setMainInfoRequest());

  try {
    await mainInfoAPI.editMainInfo(newData);
    const data = await mainInfoAPI.getMainInfo();
    dispatch(setMainInfo(data));
  } catch (error) {
    dispatch(setMainInfoFailure(error));
  }
};
