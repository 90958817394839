import React, { useEffect } from 'react';
import styles from './OrderConfirmModal.module.scss';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { onModalClose, onModalOpen } from 'redux/actions/modal';
import { withTranslation } from 'react-i18next';
import { createClient, cleanCart } from 'redux/actions/cart';
import OrderConfirmForm from './OrderConfirmForm/OrderConfirmForm';
import { cartAPI } from 'api/api';

import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('1288089251530929', null, options);

const OrderConfirmModal = ({ onModalClose, isOpen, modalType, onModalOpen, createClient, cleanCart, t, cart }) => {
  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        onModalClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onModalClose]);

  const submitForm = values => {
    onModalClose();
    const cartClient = JSON.parse(localStorage.getItem('kovilook')).cart.data;
    const fullOrder = {
      ...values,
      products: cartClient.map(({ price, count, idLP, title, titleLong, size }) => {
        if (size) return { id: idLP, price, count, comment: title.ua + ' - ' + titleLong.ua, size };
        return { id: idLP, price, count, comment: title.ua };
      }),
    };
    createClient(values);
    const totalPrice = cartClient.reduce((acc, item) => item.count * item.price + acc, 0);
    ReactPixel.track('Purchase', { currency: 'UAH', value: totalPrice });
    cartAPI(fullOrder)
      .then(() => {
        onModalOpen('Confirmed');
        cleanCart();
        setTimeout(() => onModalClose(), 2000);
      })
      .catch(() => {
        onModalOpen('Rejected');
        setTimeout(() => onModalClose(), 2000);
      });
  };

  return (
    <>
      {modalType === 'ConfirmOrder' && (
        <div className={`${styles.modal} ${isOpen && styles.open} ${isOpen === false && styles.close}`}>
          <div className={styles.modalTop}>
            <p>{t('orderConfirmModal.title')}</p>
            <Button close className={styles.closeButton} onClick={onModalClose} />
          </div>
          <OrderConfirmForm onSubmit={submitForm} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.modal.isOpen,
    modalType: state.modal.modalType,
    client: state.cart.client,
    cart: state.cart.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { onModalClose, onModalOpen, createClient, cleanCart })(OrderConfirmModal)
);
