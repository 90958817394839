import React, { useEffect } from 'react';
import styles from './SignInSignUp.module.scss';
import { connect } from 'react-redux';
import { onModalClose } from 'redux/actions/modal';
import { Button } from 'reactstrap';
import SignInForm from './SignInForm';
// import SignUpForm from './SignUpForm';
import { signIn, signUp } from 'redux/actions/user';
import Backdrop from 'components/common/Backdrop/Backdrop';

const SignInSignUpModal = ({ onModalClose, isOpen, signIn, signUp, modalType }) => {
  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        onModalClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onModalClose]);

  // const submitSignUpForm = values => {
  //   signUp(values);
  //   onModalClose();
  // };
  const submitSignInForm = values => {
    signIn(values);
    onModalClose();
  };

  return (
    <>
      {modalType === 'Auth' && (
        <div className={`${styles.modal} ${isOpen && styles.open} ${isOpen === false && styles.close}`}>
          <Button close className={styles.closeButton} onClick={onModalClose} />

          <div className={styles.modalContent}>
            <SignInForm onSubmit={submitSignInForm} />
            {/* <SignUpForm onSubmit={submitSignUpForm} /> */}
          </div>
        </div>
      )}
      <Backdrop />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.modal.isOpen,
    modalType: state.modal.modalType,
  };
};

export default connect(mapStateToProps, { onModalClose, signIn, signUp })(SignInSignUpModal);
