import React from 'react';
import styles from './NotFoundPage.module.scss';
import CodeErrorImg from 'assets/img/CodeErrorImg.png';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const NotFoundPage = ({ t }) => {
  let history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={cn('container', styles.content)}>
        <img src={CodeErrorImg} alt="code-error" />
        <h2>{t('page404.title')}</h2>
        <button type="button" className={styles.button} onClick={() => history.push('/')}>
          {t('page404.btn')}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(NotFoundPage);
