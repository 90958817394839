import React from 'react';
import styles from './SignInForm.module.scss';
import { reduxForm, Field } from 'redux-form';
import { onModalClose } from 'redux/actions/modal';
import { signIn, signOut } from 'redux/actions/user';
import { connect } from 'react-redux';
import { customInput } from 'utils/customInput';
import { withTranslation } from 'react-i18next';

import { emailValidate, maxLength, minLength, requiredField } from 'utils/validateForm';

let SignInForm = ({ handleSubmit, submitForm, onModalClose, signIn, signOut, t }) => {
  return (
    <div className={styles.modalMain}>
      <h2>{t('auth.title')}</h2>
      <form onSubmit={handleSubmit}>
        <Field
          placeholder={t('forms.placeholders.email')}
          name="email"
          component={customInput}
          type="email"
          validate={[requiredField, minLength, maxLength, emailValidate]}
          required
        />
        <Field
          placeholder={t('forms.placeholders.password')}
          name="password"
          component={customInput}
          type="password"
          validate={[requiredField, minLength, maxLength]}
          required
        />
        <button type="submit" className={styles.signButton}>
          {t('auth.form.signInBtn')}
        </button>
      </form>
    </div>
  );
};

SignInForm = reduxForm({
  form: 'signIn',
})(SignInForm);

export default withTranslation()(connect(null, { onModalClose, signIn, signOut })(SignInForm));
