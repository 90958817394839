import { combineReducers } from 'redux';
import productListReducer from './productListReducer';
import productDetailsReducer from './productDetailsReducer';
import cartReducer from './cartReducer';
import modalReducer from './modalReducer';
import categoriesReducer from './categoriesReducer';
import mainInfoReducer from './mainInfoReducer';
import userReducer from './userReducer';
import galleryReducer from './galleryReducer';
import languageReducer from './languageReducer';
import reviewItemReducer from './reviewItemReducer';
import menuReducer from './menuReducer';
import { reducer as formReducer } from 'redux-form';

const reducers = combineReducers({
  form: formReducer,
  products: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  modal: modalReducer,
  categories: categoriesReducer,
  mainInfo: mainInfoReducer,
  user: userReducer,
  gallery: galleryReducer,
  review: reviewItemReducer,
  language: languageReducer,
  menu: menuReducer,
});

export default reducers;
