import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  SET_CURRENT_PAGE,
  RESET_CURRENT_PAGE,
  SORT_BY_PRICE_HANDLER,
} from '../types/getProducts';

const initialState = {
  data: [],
  error: '',
  loading: false,
  currentPage: 1,
  pages: 0,
  priceFilter: 0,
};

const productListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.data.docs,
        loading: false,
        pages: action.data.pages,
      };

    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case RESET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: 1,
      };

    case SORT_BY_PRICE_HANDLER:
      return {
        ...state,
        priceFilter: action.payload,
      };

    default:
      return state;
  }
};

export default productListReducer;
