import { SET_PHOTO, UPDATE_PHOTO, SET_STATUS, SET_PHOTO_ADDITIONAL } from 'redux/types/gallery';
import { galleryAPI, itemAPI } from 'api/api';

export const setPhoto = data => ({ type: SET_PHOTO, data });
export const setPhotoAdditional = additional => ({ type: SET_PHOTO_ADDITIONAL, additional });
export const setStatus = status => ({ type: SET_STATUS, status });
export const updatePhotoSucsess = () => ({ type: UPDATE_PHOTO });

export const getPhoto = () => {
  return async dispatch => {
    const data = await galleryAPI.getPhoto();
    dispatch(setPhoto(data));
  };
};

export const getPhotoAdditional = () => {
  return async dispatch => {
    const additional = await galleryAPI.getPhotoAdditional();
    dispatch(setPhotoAdditional(additional));
  };
};

export const updatePhoto = (newPhoto, preview) => {
  return async dispatch => {
    try {
      const data = await galleryAPI.updatePhoto(newPhoto);
      if (preview) {
        itemAPI.setPreview(newPhoto.idItem, newPhoto.photo);
      }
      dispatch(setStatus(data.status));
      dispatch(updatePhotoSucsess());
    } catch (error) {}
  };
};

export const addPhoto = (newPhoto, preview) => {
  return async dispatch => {
    try {
      const data = await galleryAPI.addPhoto(newPhoto);
      if (preview) {
        itemAPI.setPreview(newPhoto.idItem, newPhoto.photo);
      }
      dispatch(setStatus(data.status));
      dispatch(updatePhotoSucsess());
    } catch (error) {}
  };
};

export const deletePhoto = (id, preview) => {
  return async dispatch => {
    try {
      const data = await galleryAPI.deletePhoto(id);
      if (preview) {
        itemAPI.deletePreview(preview);
      }
      dispatch(setStatus(data.status));
      dispatch(updatePhotoSucsess());
    } catch (error) {}
  };
};
