import React, { useState } from 'react';
import styles from './OrderConfirmForm.module.scss';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import infoLogo from 'assets/icons/info.svg';
import { maxLength, requiredField } from 'utils/validateForm';
import { customInput } from 'utils/customInput';
import { withTranslation } from 'react-i18next';

let OrderConfirmForm = ({ handleSubmit, initialValues, cart, t }) => {
  const [name, setName] = useState(initialValues.name);
  const [phone, setPhone] = useState(initialValues.phone);
  const [isNotValid, setIsNotValid] = useState(false);
  const handleChangeName = event => {
    setName(event.target.value);
  };
  const handleChangePhone = e => {
    if (
      /^\+380\d{3}\d{2}\d{2}\d{2}$/i.test(e.target.value) ||
      /^380\d{3}\d{2}\d{2}\d{2}$/i.test(e.target.value) ||
      /^80\d{3}\d{2}\d{2}\d{2}$/i.test(e.target.value) ||
      /^0\d{3}\d{2}\d{2}\d{2}$/i.test(e.target.value)
    ) {
      setIsNotValid(false);
      setPhone(e.target.value);
    } else {
      setIsNotValid(true);
    }
  };

  return (
    <div className={styles.modalMain}>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputBlock}>
          <Field
            placeholder={t('forms.placeholders.name')}
            name="name"
            component={customInput}
            validate={[requiredField, maxLength]}
            onChange={handleChangeName}
            value={name}
            type="text"
            required
          />
          <Field
            placeholder={t('forms.placeholders.phone')}
            name="phone"
            component="input"
            onChange={handleChangePhone}
            value={phone}
            maxLength="13"
            type="text"
            required
          />
          {isNotValid && <span style={{ color: 'red' }}>Неправильний формат номеру</span>}
        </div>
        <div className={styles.textBlock}>
          <div className={styles.logo}>
            <img src={infoLogo} alt={infoLogo} />
          </div>

          <div className={styles.text}>
            <p>{t('orderConfirmModal.info')}</p>
          </div>
        </div>
        <div className={styles.buttonBlock}>
          <input type="submit" className={styles.continueButton} value={t('orderConfirmModal.confirmBtn')} />
        </div>
      </form>
    </div>
  );
};

OrderConfirmForm = reduxForm({
  form: 'confirm',
})(OrderConfirmForm);

export default withTranslation()(
  connect(state => ({
    initialValues: state.cart.client,
    cart: state.cart.data,
  }))(OrderConfirmForm)
);
