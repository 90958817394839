import { SET_CATEGORIES, SET_CATEGORIES_REQUEST, SET_CATEGORIES_FAILURE } from 'redux/types/categories';
import { categoryAPI } from 'api/api';

const setCategories = data => {
  return {
    type: SET_CATEGORIES,
    data,
  };
};

const setCategoriesRequest = () => {
  return {
    type: SET_CATEGORIES_REQUEST,
  };
};

const setCategoriesFailure = error => {
  return {
    type: SET_CATEGORIES_FAILURE,
    error,
  };
};

export const setCategoriesTrigger = () => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      const data = await categoryAPI.getCategories();
      //TODO: Removed specific category
      const newData = data.filter(item => item.catId !== '1623316723801');
      dispatch(setCategories(newData));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const addCategory = newCategory => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.addCategory(newCategory);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const addSubCategory = (id, newSubCategory) => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.addSubCategory(id, newSubCategory);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const deleteCategory = id => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.deleteCategory(id);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const deleteSubCategory = (catId, subCatId) => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.deleteSubCategory(catId, subCatId);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const updateCategory = (id, newData) => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.editCategory(id, newData);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};

export const updateSubCategory = (catId, id, newData) => {
  return async dispatch => {
    dispatch(setCategoriesRequest());

    try {
      await categoryAPI.editSubCategory(catId, id, newData);
      const data = await categoryAPI.getCategories();
      dispatch(setCategories(data));
    } catch (error) {
      dispatch(setCategoriesFailure(error));
    }
  };
};
