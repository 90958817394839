import { DELETE_SELECTED_ITEM, UPDATE_SELECTED_ITEM, ADD_TO_CART, CREATE_CLIENT, CLEAN_CART } from '../types/cart';

const initialState = {
  data: [],
  client: { name: '', phone: '' },
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let hasItem = false;
      if (action.data.size) {
        hasItem = state.data.find(item => item._id === action.id && item.size === action.data.size);
      } else {
        hasItem = state.data.find(item => item._id === action.id);
      }
      const newData = hasItem
        ? state.data.map(el => (el._id === action.id ? { ...el, count: el.count + 1 } : el))
        : [...state.data, action.data];
      return {
        ...state,
        data: newData,
      };

    case DELETE_SELECTED_ITEM:
      return {
        ...state,
        data: state.data.filter(el => el._id + el.size !== action.id),
      };

    case UPDATE_SELECTED_ITEM:
      return {
        ...state,
        data: state.data.map(el => (el._id === action.id ? { ...el, count: el.count + action.num } : el)),
      };
    case CREATE_CLIENT:
      return {
        ...state,
        client: action.client,
      };
    case CLEAN_CART:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default cartReducer;
