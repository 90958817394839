import React from 'react';
import styles from './Header.module.scss';
import { withRouter } from 'react-router-dom';
import BlackBlock from 'components/common/Header/BlackBlock';
import Navbar from 'components/common/Header/Navbar';
import cn from 'classnames';
import { useSticky } from 'utils/customHooks';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const { ref, isSticky } = useSticky();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  return (
    <>
      {isDesktop && <BlackBlock />}
      <div className={isDesktop ? cn(`appHeader${isSticky ? ' sticky' : ''}`) : styles.appHeader} ref={ref}>
        {isSticky && isDesktop && <div style={{ height: '80px' }}></div>}
        <Navbar isDesktop={isDesktop} isMobile={isMobile} />
      </div>
    </>
  );
};

export default withRouter(Header);
