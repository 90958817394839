import { ON_MODAL_OPEN, ON_MODAL_CLOSE } from '../types/modal';

export const onModalOpen = (modalType, data) => {
  return {
    type: ON_MODAL_OPEN,
    modalType,
    data,
  };
};

export const onModalClose = () => {
  return {
    type: ON_MODAL_CLOSE,
  };
};
