import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './SearchPanel.module.scss';
import search from 'assets/icons/search.svg';
import { withTranslation } from 'react-i18next';

const SearchPanel = React.forwardRef(({ isMobile, setOpenMenu, setOpenSearch, ...props }, searchRef) => {
  const { t } = props;
  const [searchQuery, setSearchQuery] = useState('');
  let history = useHistory();
  let { url } = useRouteMatch();
  const searchItem = () => {
    history.push(`${url}search/${searchQuery}`);
  };

  const handlerKeyPress = e => {
    if (e.key === 'Enter') {
      searchItem();
      if (isMobile) {
        setOpenMenu(false);
      }
      setOpenSearch(false);
    }
  };
  return (
    <div className={styles.mainContainer} ref={searchRef}>
      <input
        className={styles.searchInput}
        type="text"
        placeholder={t('search.placeholder')}
        onChange={e => setSearchQuery(e.target.value)}
        onKeyDown={e => handlerKeyPress(e)}
        autoFocus
      />
      <img src={search} alt="search_icon" onClick={searchItem} />
    </div>
  );
});

export default withTranslation()(SearchPanel);
