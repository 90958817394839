import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default initialState => {
  initialState = JSON.parse(window.localStorage.getItem('kovilook')) || initialState;
  const middleware = [thunkMiddleware];

  const store = createStore(reducers, initialState, compose(composeEnhancers(applyMiddleware(...middleware))));
  store.subscribe(() => {
    const state = store.getState();
    const persist = {
      cart: state.cart,
    };
    window.localStorage.setItem('kovilook', JSON.stringify(persist));
  });
  return store;
};
