import { SIGN_IN, SIGN_UP, SIGN_OUT, GET_USER, TOGGLE_IS_LOADING } from '../types/user';

const initialState = {
  isAdmin: null,
  isSignIn: null,
  loading: false,
  data: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignIn: true,
      };
    case SIGN_UP:
      return {
        ...state,
        isSignIn: true,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAdmin: false,
        isSignIn: false,
      };
    case GET_USER:
      return {
        ...state,
        isAdmin: action.payload,
        data: action.data,
      };
    case TOGGLE_IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
