import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
// import { far, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faTimes,
  faCaretDown,
  faUserCircle,
  faShoppingCart,
  faSearch,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  // fab,
  // far,
  faBars,
  faUserCircle,
  faSearch,
  faShoppingCart,
  faTimes,
  // faInstagram,
  // faFacebook,
  faCaretDown
);
