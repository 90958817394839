import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import styles from './Footer.module.scss';
import list_white from 'assets/icons/list_white.svg';
import close_white from 'assets/icons/close_white.svg';
import facebook from 'assets/icons/facebook.svg';
import youtube from 'assets/icons/youtube.svg';
import telegram from 'assets/icons/telegram.svg';
import instagram from 'assets/icons/instagram.svg';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import Logo_black from 'assets/img/Logo_black.svg';
import { getPhotoAdditional } from 'redux/actions/gallery';
import { getMainInfo } from 'redux/actions/mainInfo';

const Footer = ({
  categories,
  address,
  email,
  phones1,
  phones2,
  phones3,
  t,
  i18n,
  getPhotoAdditional,
  additional,
  getMainInfo,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [policy, setPolicy] = useState({});
  let history = useHistory();
  let { url } = useRouteMatch();

  const getMainInfoCallback = useCallback(() => getMainInfo(), [getMainInfo]);

  useEffect(() => {
    if (!Array.isArray(additional)) return;
    if (additional.length === 0) return;
    const newPolicy = {};
    additional.forEach(doc => {
      if (doc.category === 'offer') newPolicy.newOffer = doc.photo;
      if (doc.category === 'term') newPolicy.newTerm = doc.photo;
      if (doc.category === 'faq') newPolicy.newFaq = doc.photo;
      if (doc.category === 'delivery') newPolicy.newDelivery = doc.photo;
    });
    setPolicy(newPolicy);
  }, [additional]);

  useEffect(() => {
    getPhotoAdditional();
    getMainInfoCallback();
  }, [getMainInfoCallback, getPhotoAdditional]);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const handleGoHistory = goTo => {
    if (goTo === '') {
      history.push(`/`);
    } else {
      history.push(`/${goTo}`);
    }
  };
  return (
    <footer className={styles.footer}>
      {isMobile && (
        <>
          <div className={styles.logo} onClick={() => handleGoHistory('')}>
            <img src={Logo_black} alt="logo" />
          </div>
          <div className={styles.dropDownMenus} onClick={() => setOpenMenu(!openMenu)}>
            <span className={styles.openMenu}>
              {t('footer.menu')} <img src={openMenu ? close_white : list_white} alt="list_icon" />
            </span>
          </div>
          {openMenu && (
            <div className={styles.menuList}>
              <nav className={styles.navigation}>
                {Array.isArray(categories) &&
                  categories.map(item => (
                    <Link to={{ pathname: `${url}products/${item._id}` }} key={item._id}>
                      {i18n.language === 'uk' && item.name.ua}
                      {i18n.language === 'ru' && item.name.ru}
                      {i18n.language === 'en' && item.name.en}
                    </Link>
                  ))}
              </nav>
            </div>
          )}
          {policy.newDelivery && (
            <div className={styles.dropDownMenus}>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newDelivery}`}>
                {t('footer.additionalInfo.payment')}
              </a>
            </div>
          )}
          {policy.newOffer && (
            <div className={styles.dropDownMenus}>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newOffer}`}>
                {t('footer.additionalInfo.offer')}
              </a>
            </div>
          )}
          {policy.newTerm && (
            <div className={styles.dropDownMenus}>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newTerm}`}>
                {t('footer.additionalInfo.policy')}
              </a>
            </div>
          )}
          {policy.divnewFaq && (
            <div className={styles.dropDownMenus}>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newFaq}`}>
                {t('footer.additionalInfo.faq')}
              </a>
            </div>
          )}
          <div className={styles.contacts}>
            <span className={styles.keys}>{t('footer.contacts.fop')}:</span>
            <span>{t('footer.contacts.kostya')}</span>
            <span className={styles.keys}>{t('footer.contacts.address')}:</span>
            <span>
              {address && i18n.language === 'uk' && address.ua}
              {address && i18n.language === 'ru' && address.ru}
              {address && i18n.language === 'en' && address.en}
            </span>
            <span className={styles.keys}>{t('footer.contacts.phone')}:</span>
            <div>
              <div>{phones1}</div>
              <div>{phones2}</div>
              <div>{phones3}</div>
            </div>
            <span className={styles.keys}>{t('footer.contacts.email')}:</span>
            <a href="mailto:email">{email}</a>
          </div>
          <div className={styles.socialNetworks}>
            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/kovilook.com/">
              <img src={facebook} alt="facebook_icon" />
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/kovilook/">
              <img src={instagram} alt="instagram_icon" />
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/">
              <img src={youtube} alt="youtube_icon" />
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://web.telegram.org/">
              <img src={telegram} alt="telegram_icon" />
            </a>
          </div>
          <div className={styles.rights}>
            <p>© 2019 MAGISOFT, ALL RIGHTS RESERVED</p>
          </div>
        </>
      )}
      {isDesktop && (
        <>
          <div className={cn('container', styles.footer_main)}>
            <div className={styles.logo}>
              <div onClick={() => handleGoHistory('')}>
                <img src={Logo_black} alt="logo" />
              </div>
              <div className={styles.socialNetworks}>
                <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/kovilook.com/">
                  <img src={facebook} alt="facebook_icon" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/kovilook/">
                  <img src={instagram} alt="instagram_icon" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/">
                  <img src={youtube} alt="youtube_icon" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://web.telegram.org/">
                  <img src={telegram} alt="telegram_icon" />
                </a>
              </div>
            </div>
            <nav className={styles.navigation}>
              {Array.isArray(categories) &&
                categories.map(item => (
                  <Link to={{ pathname: `${url}products/${item._id}` }} key={item._id}>
                    {i18n.language === 'uk' && item.name.ua}
                    {i18n.language === 'ru' && item.name.ru}
                    {i18n.language === 'en' && item.name.en}
                  </Link>
                ))}
            </nav>
            <div className={styles.additional}>
              {policy.newDelivery && (
                <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newDelivery}`}>
                  {t('footer.additionalInfo.payment')}
                </a>
              )}
              {policy.newOffer && (
                <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newOffer}`}>
                  {t('footer.additionalInfo.offer')}
                </a>
              )}
              {policy.newTerm && (
                <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newTerm}`}>
                  {t('footer.additionalInfo.policy')}
                </a>
              )}
              {policy.newFaq && (
                <a rel="noopener noreferrer" target="_blank" href={`https://www.kovilook.com/${policy.newFaq}`}>
                  {t('footer.additionalInfo.faq')}
                </a>
              )}
            </div>
            <div className={styles.contacts}>
              <span className={styles.keys}>{t('footer.contacts.fop')}:</span>
              <span>{t('footer.contacts.kostya')}</span>
              <span className={styles.keys}>{t('footer.contacts.address')}:</span>
              <span>
                {address && i18n.language === 'uk' && address.ua}
                {address && i18n.language === 'ru' && address.ru}
                {address && i18n.language === 'en' && address.en}
              </span>
              <span className={styles.keys}>{t('footer.contacts.phone')}:</span>
              <div>
                <div>{phones1}</div>
                <div>{phones2}</div>
                <div>{phones3}</div>
              </div>
              <span className={styles.keys}>{t('footer.contacts.email')}:</span>
              <a href="mailto:email">{email}</a>
            </div>
          </div>
          <div className={styles.rights}>
            <p>© 2019 MAGISOFT, ALL RIGHTS RESERVED</p>
          </div>
        </>
      )}
    </footer>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.categories.data,
    phones1: state.mainInfo.data.phones1,
    phones2: state.mainInfo.data.phones2,
    phones3: state.mainInfo.data.phones3,
    email: state.mainInfo.data.email,
    address: state.mainInfo.data.address,
    additional: state.gallery.additional,
  };
};

export default withTranslation()(connect(mapStateToProps, { getPhotoAdditional, getMainInfo })(Footer));
