import React from 'react';
import styles from './Authorization.module.scss';
import user from 'assets/icons/user.svg';
import SignInSignUpModal from 'components/common/SignInSignUpModal';
import { onModalOpen } from 'redux/actions/modal';
import { connect } from 'react-redux';

const AuthorizationComponent = ({ onModalOpen }) => {
  return (
    <div className={styles.auth}>
      <img className={styles.authImg} onClick={() => onModalOpen('Auth')} src={user} alt="user_icon" />
      <SignInSignUpModal />
    </div>
  );
};

export default connect(null, { onModalOpen })(AuthorizationComponent);
