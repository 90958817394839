import React, { useState } from 'react';
import goUp from 'assets/icons/Go_UP.svg';
import styles from './ScrollToTopButton.module.scss';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = Math.ceil(currPos.y) < -100;
      if (isShow !== isVisible) setIsVisible(isShow);
    },
    [isVisible]
  );
  const onScrollHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {isVisible && (
        <button type="button" onClick={onScrollHandler} className={styles.buttonGoUp}>
          <img className={styles.imgGoUp} src={goUp} alt="goUp" />
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
