import React from 'react';
import styles from './Header.module.scss';
import cn from 'classnames';
import AuthorizationComponent from 'components/common/AuthorizationComponent';
import { connect } from 'react-redux';
import { signOut } from 'redux/actions/user';
import { withTranslation } from 'react-i18next';

const BlackBlock = ({ phones1, phones2, phones3, isSignIn, signOut, i18n }) => {
  return (
    <div className={styles.blackBlock}>
      <div className={cn('container', styles.blackBlockContainer)}>
        <div className={styles.changeLanguageContainer}>
          <button
            className={styles.languageButton}
            style={{ fontWeight: i18n.language === 'ru' && 'bold' }}
            onClick={() => {
              i18n.changeLanguage('ru');
            }}
          >
            RU
          </button>
          <button
            className={styles.languageButton}
            style={{ fontWeight: i18n.language === 'uk' && 'bold' }}
            onClick={() => i18n.changeLanguage('uk')}
          >
            UA
          </button>
          <button
            className={styles.languageButton}
            style={{ fontWeight: i18n.language === 'en' && 'bold' }}
            onClick={() => i18n.changeLanguage('en')}
          >
            EN
          </button>
        </div>
        {isSignIn ? (
          <button className={styles.logoutBtn} onClick={signOut}>
            Вихід
          </button>
        ) : (
          <AuthorizationComponent />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    phones1: state.mainInfo.data.phones1,
    phones2: state.mainInfo.data.phones2,
    phones3: state.mainInfo.data.phones3,
    isSignIn: state.user.isSignIn,
  };
};

export default withTranslation()(connect(mapStateToProps, { signOut })(BlackBlock));
