import React from 'react';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Routes from 'routes';
import ScrollToTopButton from 'components/common/ScrollToTopButton';
import { getUser } from 'redux/actions/user';
import { connect } from 'react-redux';

import 'normalize.css';
import 'styles/index.scss';

// fonts
import 'styles/fonts/MyriadPro-Regular.ttf';
import 'styles/fonts/MyriadPro-Light.ttf';
import 'styles/fonts/MyriadPro-Black.ttf';
import 'styles/fonts/MyriadPro-Bold.ttf';
import 'styles/fonts/MyriadPro-Semibold.ttf';

const App = ({ getUser }) => {
  getUser();
  return (
    <>
      <Header />
      <div className="main-content">
        <Routes />
      </div>
      <ScrollToTopButton />
      <Footer />
    </>
  );
};

export default connect(null, { getUser })(App);
