import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import list from 'assets/icons/list.svg';
import close from 'assets/icons/close.svg';
import search from 'assets/icons/search.svg';
import styles from './Header.module.scss';
import basket from 'assets/icons/basket.svg';
import basket_white from 'assets/icons/basket_white.svg';
import burger from 'assets/icons/burger.svg';
import adminRoute from 'assets/icons/admin-settings.svg';
import SearchPanel from 'components/common/SearchPanel';
import cn from 'classnames';
import { useOnClickOutside } from 'utils/customHooks';
import { totalPriceSelector } from 'redux/selectors/totalPriceSelector';
import { totalCountSelector } from 'redux/selectors/totalCountSelector';
import { resetCurrentPage } from 'redux/actions/getProducts';
import { setCategoriesTrigger } from 'redux/actions/categories';
import { withTranslation } from 'react-i18next';
import { setOpenMenu } from 'redux/actions/menu';
import Logo from 'assets/img/Logo.svg';
import Logo_black from 'assets/img/Logo_black.svg';

const Navbar = ({
  isDesktop,
  isMobile,
  totalPrice,
  resetCurrentPage,
  setCategoriesTrigger,
  categories,
  isAdmin,
  t,
  totalCount,
  i18n,
  setOpenMenu,
  openMenu,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState({ open: false, id: null });
  const [openSearch, setOpenSearch] = useState(false);
  let { url } = useRouteMatch();
  let history = useHistory();
  const ref = useRef();
  const menuref = useRef();
  useOnClickOutside(ref, () => setOpenSearch(false));
  useOnClickOutside(menuref, () => {
    setOpenMenu(false);
  });
  const openMenuCallback = useCallback(() => setOpenMenu(!openMenu), [setOpenMenu, openMenu]);
  const closeAndResetMenu = useCallback(() => {
    setOpenMenu(false);
    resetCurrentPage();
  }, [resetCurrentPage, setOpenMenu]);

  const setCategoriesTriggerCallback = useCallback(() => setCategoriesTrigger(), [setCategoriesTrigger]);
  useEffect(() => {
    setCategoriesTriggerCallback();
  }, [setCategoriesTriggerCallback]);

  return (
    <header className={cn('stickyInner', styles.appHeader)}>
      <>
        {isMobile && (
          <>
            {openMenu ? (
              <div className={styles.dropHeader} ref={menuref}>
                <div className={styles.firstPart} onClick={openMenuCallback}>
                  <span className={styles.dropDownTitle}>{t('navbar.close')}</span>
                </div>
                <SearchPanel searchRef={ref} isMobile setOpenMenu={setOpenMenu} setOpenSearch={setOpenSearch} />
                <div className={styles.dropDownMenu}>
                  <div className={styles.menu}>
                    {Array.isArray(categories) &&
                      categories.map(item => {
                        return (
                          <div
                            className={styles.menuItem}
                            onClick={() => {
                              setOpenSubMenu({ open: !openSubMenu.open, id: item._id });
                            }}
                            key={item._id}
                          >
                            <div className={styles.menuItem_notDrop}>
                              {i18n.language === 'uk' && item.name.ua}
                              {i18n.language === 'ru' && item.name.ru}
                              {i18n.language === 'en' && item.name.en}
                              {item.subCategorys.length !== 0 && (
                                <img
                                  src={openSubMenu.open && openSubMenu.id === item._id ? close : list}
                                  alt="list-arrow"
                                />
                              )}
                            </div>
                            {item.subCategorys.length !== 0 && openSubMenu.open && openSubMenu.id === item._id && (
                              <div className={styles.subMenu}>
                                {item.subCategorys.map(subItem => (
                                  <Link
                                    to={`${url}products/${item._id}/${subItem._id}`}
                                    key={subItem._id}
                                    onClick={closeAndResetMenu}
                                  >
                                    {i18n.language === 'uk' && subItem.name.ua}
                                    {i18n.language === 'ru' && subItem.name.ru}
                                    {i18n.language === 'en' && subItem.name.en}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className={styles.changeLangContainer}>
                    <button className={styles.languageButton} onClick={() => i18n.changeLanguage('ru')}>
                      RU
                    </button>
                    <button className={styles.languageButton} onClick={() => i18n.changeLanguage('uk')}>
                      UA
                    </button>
                    <button className={styles.languageButton} onClick={() => i18n.changeLanguage('en')}>
                      EN
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.firstPart}>
                  <div className={styles.menuBar} onClick={() => setOpenMenu(!openMenu)}>
                    <img src={burger} alt="menu_icon" />
                  </div>
                  <div className={styles.logo} onClick={() => history.push('/')}>
                    <img src={Logo_black} alt="logo" />
                  </div>
                  <div className={styles.basket}>
                    {/* <AuthorizationComponent isMobile={isMobile} /> */}
                    <div className={styles.icons} onClick={() => history.push('/cart')}>
                      <img src={basket_white} alt="basket_icon" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isDesktop && (
          <>
            <div className={cn('container', styles.navbar)}>
              <div className={styles.logo} onClick={() => history.push('/')}>
                <img src={Logo} alt="logo" />
              </div>
              <ul className={styles.menu}>
                {Array.isArray(categories) &&
                  categories.map(item => {
                    return (
                      <li key={item._id} className={styles.menuItem}>
                        <Link to={`${url}products/${item._id}`} onClick={resetCurrentPage}>
                          {i18n.language === 'uk' && item.name.ua}
                          {i18n.language === 'ru' && item.name.ru}
                          {i18n.language === 'en' && item.name.en}
                        </Link>
                        {item.subCategorys.length !== 0 && (
                          <>
                            <img src={list} alt="list-arrow" />
                            <div className={styles.subMenu}>
                              {item.subCategorys.map(subItem => {
                                return (
                                  <Link
                                    to={`${url}products/${item._id}/${subItem._id}`}
                                    key={subItem._id}
                                    onClick={resetCurrentPage}
                                  >
                                    {i18n.language === 'uk' && subItem.name.ua}
                                    {i18n.language === 'ru' && subItem.name.ru}
                                    {i18n.language === 'en' && subItem.name.en}
                                  </Link>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </li>
                    );
                  })}
              </ul>

              <div className={styles.cart}>
                <div className={styles.icons}>
                  <div className={styles.searchIconContainer} ref={ref}>
                    {openSearch ? (
                      <>
                        <img
                          src={close}
                          className={styles.crossIcon}
                          onClick={() => setOpenSearch(false)}
                          alt="some_icon"
                        />
                      </>
                    ) : (
                      <img
                        src={search}
                        className={styles.searchIcon}
                        onClick={() => setOpenSearch(true)}
                        alt="some_icon"
                      />
                    )}
                    {openSearch && <SearchPanel searchRef={ref} setOpenSearch={setOpenSearch} />}
                  </div>
                </div>
                {isAdmin ? (
                  <div>
                    <Link to="/admin">
                      <img src={adminRoute} alt="admin-menu" className={styles.adminSettings} />
                    </Link>
                  </div>
                ) : (
                  <div className={styles.cartContainer} onClick={() => history.push('/cart')}>
                    <div className={styles.cart}>
                      <p className={styles.counter}>{totalCount}</p>
                      <img src={basket} alt="cart" className={styles.cartLogo} />
                    </div>
                    <div className={styles.infoCart}>
                      <span>{t('navbar.cart')}</span>
                      <span>
                        {totalPrice} {t('navbar.currency')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    totalPrice: totalPriceSelector(state),
    totalCount: totalCountSelector(state),
    categories: state.categories.data,
    isAdmin: state.user.isAdmin,
    openMenu: state.menu.openMenu,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { resetCurrentPage, setCategoriesTrigger, setOpenMenu })(Navbar)
);
