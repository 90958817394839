import { SIGN_IN, SIGN_UP, SIGN_OUT, GET_USER, TOGGLE_IS_LOADING } from '../types/user';
import { loginAPI } from '../../api/api';

export const toggleIsLoading = isLoading => ({ type: TOGGLE_IS_LOADING, isLoading });

export const signIn = info => {
  return async dispatch => {
    dispatch(toggleIsLoading(true));
    try {
      await loginAPI.login(info);
      const data = await loginAPI.getUser();
      const payload = data.role === 'admin' ? true : false;
      dispatch(toggleIsLoading(false));
      dispatch({ type: SIGN_IN });
      dispatch({ type: GET_USER, data, payload });
    } catch (error) {}
  };
};

export const signUp = dataUser => {
  return async dispatch => {
    dispatch(toggleIsLoading(true));
    try {
      await loginAPI.registerUser(dataUser);
      const data = await loginAPI.getUser();
      const payload = data.role === 'admin' ? true : false;
      dispatch(toggleIsLoading(false));
      dispatch({ type: SIGN_UP });
      dispatch({ type: GET_USER, data, payload });
    } catch (error) {}
  };
};
// export const signUp = (_id, newData) => async dispatch => {
//   dispatch(toggleIsLoading(true));
//   await loginAPI.editMainInfo(_id, newData);
//   const data = await loginAPI.___________();
//   dispatch(toggleIsLoading(false));
//   dispatch(setMainInfo(data));
// };

export const signOut = () => async dispatch => {
  dispatch(toggleIsLoading(true));
  await loginAPI.logout();
  dispatch(toggleIsLoading(false));
  dispatch({ type: SIGN_OUT });
};

export const getUser = () => {
  return async dispatch => {
    dispatch(toggleIsLoading(true));
    try {
      const data = await loginAPI.getUser();
      const payload = data.role === 'admin' ? true : false;
      dispatch(toggleIsLoading(false));
      dispatch({ type: SIGN_IN });
      dispatch({ type: GET_USER, data, payload });
    } catch (error) {}
  };
};
