export const requiredField = value => (value ? undefined : `Поле обов'язкове`);

export const minLength = value => (value.length < 1 ? `Мінімальна кількість символів - 4` : undefined);

export const maxLength = value => (value.length > 50 ? `Максимальна кількість символів - 50` : undefined);
export const phoneValidate = value => {
  return value &&
    !/^\+380\d{3}\d{2}\d{2}\d{2}$/i.test(value) &&
    !/^380\d{3}\d{2}\d{2}\d{2}$/i.test(value) &&
    !/^80\d{3}\d{2}\d{2}\d{2}$/i.test(value) &&
    !/^0\d{3}\d{2}\d{2}\d{2}$/i.test(value)
    ? 'Неправильний формат номеру'
    : undefined;
};

export const emailValidate = value => {
  return value &&
    !/^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i.test(value)
    ? 'Неправильний email'
    : undefined;
};

export const confirmPassword = (value, allValues) =>
  value === allValues.password ? undefined : 'Паролі не співпадають';
