import React from 'react';
import 'styles/error.scss';

export const customInput = ({ input, meta, defaultValue, ...props }) => {
  return (
    <>
      <input {...input} {...props} value={!!defaultValue ? defaultValue : undefined} />
      {meta.touched && meta.error && <span className="error">{meta.error}</span>}
    </>
  );
};
