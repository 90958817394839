import { SET_OPEN_MENU } from '../types/menu';
const initialState = {
  openMenu: false,
};
const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.data,
      };
    default:
      return state;
  }
};

export default menuReducer;
