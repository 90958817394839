import { SET_MAIN_INFO, SET_MAIN_INFO_REQUEST, SET_MAIN_INFO_FAILURE } from '../types/mainInfo';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const mainInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_INFO:
      return {
        ...state,
        loading: false,
        data: action.data,
      };

    case SET_MAIN_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SET_MAIN_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default mainInfoReducer;
