import { ON_MODAL_OPEN, ON_MODAL_CLOSE } from '../types/modal';

const initialState = {
  isOpen: false,
  modalType: null,
  data: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_MODAL_OPEN:
      return {
        ...state,
        isOpen: true,
        modalType: action.modalType,
        data: action.data,
      };

    case ON_MODAL_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};

export default modalReducer;
