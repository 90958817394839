import {
  DELETE_SELECTED_ITEM,
  UPDATE_SELECTED_ITEM,
  ADD_TO_CART,
  CREATE_CLIENT,
  CLEAN_CART,
  // BUY_ITEMS,
} from '../types/cart';

export const addItem = data => {
  const { _id } = data;
  const newData = {
    ...data,
    count: 1,
  };
  return {
    type: ADD_TO_CART,
    data: newData,
    id: _id,
  };
};

export const deleteItem = _id => async dispatch => {
  dispatch({
    type: DELETE_SELECTED_ITEM,
    id: _id,
  });
};

export const updateItemsCount = (num, id) => {
  return {
    type: UPDATE_SELECTED_ITEM,
    id,
    num,
  };
};

export const createClient = client => {
  return {
    type: CREATE_CLIENT,
    client,
  };
};

export const cleanCart = () => {
  return {
    type: CLEAN_CART,
  };
};
