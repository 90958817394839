import React from 'react';
import { connect } from 'react-redux';
import styles from './CartItem.module.scss';
import { deleteItem, updateItemsCount } from 'redux/actions/cart';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const CartItem = props => {
  const isMobile = useMediaQuery({
    query: '(max-width: 568px)',
  });

  const { _id, title, count, photo, price, deleteItem, updateItemsCount, t, i18n, size } = props;
  const countPrice = price * count;
  return (
    <div className={styles.cartItem} key={_id}>
      <div className={styles.itemImage} style={{ backgroundImage: `url(https://www.kovilook.com/${photo})` }}></div>
      <div className={styles.containerInfoPart}>
        <div className={styles.infoPart}>
          <div className={styles.itemName}>
            {i18n.language === 'uk' && title.ua}
            {i18n.language === 'ru' && title.ru}
            {i18n.language === 'en' && title.en}
          </div>

          {!isMobile && (
            <div className={styles.itemCount}>
              <button onClick={() => updateItemsCount(-1, _id)} disabled={!(count - 1)}>
                -
              </button>
              <span>{count}</span>
              <button onClick={() => updateItemsCount(1, _id)}>+</button>
            </div>
          )}

          {!isMobile && (
            <div className={styles.itemPrice}>
              <span>{countPrice}</span>&nbsp;{t('productsItem.currency')}
            </div>
          )}
        </div>
        {size && (
          <div className={styles.wrapperSizes}>
            <label>{t('productsItem.sizes')}:</label>
            <span>{size}</span>
          </div>
        )}
      </div>
      <button className={styles.deleteButton} onClick={() => deleteItem(_id + size, countPrice)}></button>
      {isMobile && (
        <div className={styles.mobileCount}>
          <div className={styles.itemCount}>
            <button onClick={() => updateItemsCount(-1, _id)} disabled={!(count - 1)}>
              -
            </button>
            <span>{count}</span>
            <button onClick={() => updateItemsCount(1, _id)}>+</button>
          </div>

          <div className={styles.itemPrice}>
            <span>{countPrice}</span>&nbsp;{t('productsItem.currency')}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  deleteItem,
  updateItemsCount,
};

export default withTranslation()(connect(null, mapDispatchToProps)(CartItem));
