import { SET_PHOTO, UPDATE_PHOTO, SET_STATUS, SET_PHOTO_ADDITIONAL } from 'redux/types/gallery';

const initialState = {
  loading: false,
  data: [],
  status: null,
  additional: [],
};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHOTO:
      return {
        ...state,
        data: action.data,
      };

    case SET_PHOTO_ADDITIONAL:
      return {
        ...state,
        additional: action.additional,
      };

    case UPDATE_PHOTO:
      return {
        ...state,
        status: null,
      };

    case SET_STATUS:
      return {
        ...state,
        status: action.status,
      };

    default:
      return state;
  }
};

export default galleryReducer;
