import { SET_LANGUAGE } from '../types/language';

const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        local: action.local,
      };
    default:
      return state;
  }
};

export default languageReducer;
