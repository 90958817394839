import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Cart from 'components/pages/Cart';
import NotFoundPage from 'components/pages/NotFoundPage';
// import Home from 'components/pages/Home';
// import ProductDetailsPage from 'components/pages/ProductDetailsPage';
// import ProductsPage from 'components/pages/ProductsPage';

const Home = React.lazy(() => import('components/pages/Home'));
const ProductsPage = React.lazy(() => import('components/pages/ProductsPage'));
const ProductDetailsPage = React.lazy(() => import('components/pages/ProductDetailsPage'));
const SearchPage = React.lazy(() => import('components/pages/SearchPage'));
const AdminPage = React.lazy(() => import('components/pages/AdminPage'));

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <React.Suspense fallback={<div />}>
            <Home />
          </React.Suspense>
        </Route>
        <Route path="/cart" component={Cart} />
        <Route
          exact
          path="/products/:id"
          component={({ match }) => (
            <React.Suspense fallback={<div />}>
              <ProductsPage {...match.params} />
            </React.Suspense>
          )}
        />
        <Route
          exact
          path="/products/:id/:subId"
          component={({ match }) => (
            <React.Suspense fallback={<div />}>
              <ProductsPage {...match.params} />
            </React.Suspense>
          )}
        />
        <Route exact path="/search/:title">
          <React.Suspense fallback={<div />}>
            <SearchPage />
          </React.Suspense>
        </Route>
        <Route
          exact
          path="/item/:productId"
          component={props => (
            <React.Suspense fallback={<div />}>
              <ProductDetailsPage {...props} />
            </React.Suspense>
          )}
        />
        <Route path="/admin">
          <React.Suspense fallback={<div />}>
            <AdminPage />
          </React.Suspense>
        </Route>
        <Route path="/404" component={NotFoundPage} />
        <Redirect from="/" to="/404" />
      </Switch>
    </>
  );
};
export default Routes;
