import { SET_ITEM, SET_ITEM_REQUEST, SET_ITEM_FAILURE } from '../types/getItem';

const initialState = {
  data: {},
  error: '',
  loading: false,
};

const productDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SET_ITEM:
      return {
        ...state,
        data: action.data ? { ...action.data, sizes: JSON.parse(action.data.sizes ?? '{}') } : {},
        loading: false,
      };

    case SET_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default productDetailsReducer;
