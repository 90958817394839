import React from 'react';
import { connect } from 'react-redux';
import { onModalOpen } from 'redux/actions/modal';
import { totalPriceSelector } from 'redux/selectors/totalPriceSelector';
import CartItem from './CartItem';
import OrderConfirmModal from 'components/common/OrderConfirmModal/OrderConfirmModal';
import Backdrop from 'components/common/Backdrop/Backdrop';
import ConfirmedModal from 'components/common/ConfirmedModal/ConfirmedModal';
import styles from './Cart.module.scss';
import { createClient, cleanCart } from 'redux/actions/cart';
import { withTranslation } from 'react-i18next';

const Cart = props => {
  const { items, totalPrice, history, onModalOpen, createClient, cleanCart, t } = props;
  return (
    <div className="container">
      <div className={styles.cart}>
        <h1>{t('cart.title')}</h1>
        {items.length !== 0 ? (
          <>
            {items.map(el => (
              <CartItem {...el} key={el._id + el.size} />
            ))}
            <div className={styles.bottomNavigation}>
              <button className={styles.buttonReturn} onClick={() => history.goBack()}>
                {t('cart.buttons.continue')}
              </button>
              <button className={styles.buttonBuy} onClick={() => onModalOpen('ConfirmOrder')}>
                {t('cart.buttons.confirm')}
              </button>
              <div>
                {t('navbar.cart')}&nbsp;<span>{totalPrice}</span>&nbsp;{t('navbar.currency')}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.emptyCartMessage}>{t('cart.empty')}</div>
        )}
      </div>
      <OrderConfirmModal createClient={createClient} cleanCart={cleanCart} />
      <ConfirmedModal />

      <Backdrop />
    </div>
  );
};

const mapStateToProps = state => ({
  items: state.cart.data,
  client: state.cart.client,
  totalPrice: totalPriceSelector(state),
});
export default withTranslation()(connect(mapStateToProps, { onModalOpen, createClient, cleanCart })(Cart));
