import React, { useEffect } from 'react';
import styles from './ConfirmedModal.module.scss';
import doneLogo from '../../../assets/icons/done.svg';
import rejectLogo from '../../../assets/icons/deleteItem.svg';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { onModalClose } from 'redux/actions/modal';

const ConfirmedModal = ({ onModalClose, isOpen, modalType, t, confirmReview }) => {
  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        onModalClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onModalClose]);

  const PIXEL = (f, b, e, v, n, t, s) => {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
    window.fbq('init', '358585985730098');
    window.fbq('track', 'PageView');
    window.fbq('track', 'Purchase');
  };
  return (
    <>
      {modalType === 'Confirmed' && (
        <>
          <div className={`${styles.modal} ${isOpen && styles.open} ${isOpen === false && styles.close}`}>
            <div>
              <div className={styles.modalTop}>
                <p>{t('confirmedModal.title')}</p>
              </div>

              <div className={styles.modalMain}>
                <div className={styles.modalConfirmIcon}>
                  <img src={doneLogo} alt={doneLogo} />
                </div>
                <div>
                  <p className={styles.modalText}>{t('confirmedModal.info')}</p>
                </div>
              </div>
            </div>
          </div>
          <script>{PIXEL(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')}</script>
          <noscript>
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=358585985730098&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      )}
      {modalType === 'Rejected' && (
        <div className={`${styles.modal} ${isOpen && styles.open} ${isOpen === false && styles.close}`}>
          <div>
            <div className={styles.modalTop}>
              <p>{t('rejectedModal.title')}</p>
            </div>

            <div className={styles.modalMain}>
              <div className={styles.modalConfirmIcon}>
                <img src={rejectLogo} alt={rejectLogo} />
              </div>
              <div>
                <p className={styles.modalText}>{t('rejectedModal.info')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.modal.isOpen,
    modalType: state.modal.modalType,
  };
};

export default withTranslation()(connect(mapStateToProps, { onModalClose })(ConfirmedModal));
